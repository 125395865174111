import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { ListEnd } from 'lucide-react';
import { useState } from 'react';

import { PrimaryAsset } from '@/api/types/node';
import { PrimaryAssetRecord } from '@/api/types/node';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { DataTableFacetedFilter } from './FacetedFilter';
import { GenerationSidebar } from './GenerateDocs/GenerationSidebar';
import { NewAssetAction } from './NewAssetAction';
import { TagsFilter } from './TagsFilter';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  data: PrimaryAssetRecord[];
}

export function DataTableToolbar<TData>({ table, data }: DataTableToolbarProps<TData>) {
  const [isGenerationSidebarOpen, setIsGenerationSidebarOpen] = useState(false);

  const isFiltered = table.getState().columnFilters.length > 0;
  const currentSelection = table.getState().rowSelection;
  const selectedAssetsIds = Object.entries(currentSelection)
    .filter(([key, value]) => value)
    .map(([key, value]) => key);
  const hasSelection = selectedAssetsIds.length > 0;
  // const versionIds = selectedAssetsIds
  //   .map((id) => {
  //     const asset = data.find((asset) => asset.id === id);
  //     return asset?.most_recent_version?.id;
  //   })
  //   .filter((id) => id !== undefined) as string[];
  const selectedRecords = data.filter((asset) => selectedAssetsIds.includes(asset.id));

  const typeOptions = [
    {
      name: 'Page',
      value: PrimaryAsset.PAGE,
    },
    {
      name: 'Codebase',
      value: PrimaryAsset.CODEBASE,
    },
    {
      name: 'PDF',
      value: PrimaryAsset.FILE,
    },
  ];
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Search assets"
          value={(table.getColumn('display_name')?.getFilterValue() as string) ?? ''}
          onChange={(event) => table.getColumn('display_name')?.setFilterValue(event.target.value)}
          className="h-8 w-full sm:w-56 lg:w-80"
        />
        {table.getColumn('kind') && (
          <div className="hidden md:block">
            <DataTableFacetedFilter
              column={table.getColumn('kind')}
              title="Type"
              options={typeOptions.map((type) => ({
                label: type.name,
                value: type.value,
              }))}
            />
          </div>
        )}
        {/* Hide status filter for now */}
        {/* {table.getColumn('status') && (
          <div className="hidden md:block">
            <DataTableFacetedFilter
              column={table.getColumn('status')}
              title="Status"
              options={Object.entries(NodeStatusDisplayName)
                .filter(([key]) => key !== NodeStatus.USER_DATA)
                .map(([key, value]) => ({
                  label: value,
                  value: key,
                }))}
            />
          </div>
        )} */}
        {table.getColumn('tags') && (
          <div className="hidden md:block">
            <TagsFilter column={table.getColumn('tags')} title="Tags" />
          </div>
        )}
        {isFiltered && (
          <Button variant="ghost" onClick={() => table.resetColumnFilters()} className="h-8 px-2 lg:px-3">
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      {!hasSelection && (
        <div className="flex items-center space-x-2">
          {/* <div className="">
          <CreateCollectionButton />
        </div> */}
          {/* Leaving out the visibility toggle for now */}
          {/* <DataTableViewOptions table={table} /> */}

          <NewAssetAction />
        </div>
      )}
      {hasSelection && (
        <div className="hidden sm:block">
          <Button className="ml-2" size="sm" onClick={() => setIsGenerationSidebarOpen(true)}>
            Generate Selected <ListEnd className="ml-2 h-4 w-4" />
          </Button>
        </div>
      )}
      <GenerationSidebar
        selectedRecords={selectedRecords}
        open={isGenerationSidebarOpen}
        onOpenChange={setIsGenerationSidebarOpen}
      />
    </div>
  );
}
