import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { useEffect } from 'react';
import { z } from 'zod';

import DefaultLayout from '@/components/LayoutDefault';
import { LibraryTable } from '@/components/LibraryTable/LibraryTable';
import { DEFAULT_SORTING } from '@/components/LibraryTable/useLibraryTable';
import { useOrg } from '@/contexts/OrgContext';

const DEFAULT_PAGE_SIZE = 30;

const librarySearchSchema = z.object({
  pageSize: fallback(z.number(), DEFAULT_PAGE_SIZE).default(DEFAULT_PAGE_SIZE),
  pageIndex: fallback(z.number(), 0).default(0),
  generateRowId: fallback(z.string(), '').default(''),
  columnFilters: fallback(
    z.array(
      z.object({
        id: z.string(),
        value: z.unknown().refine((x) => x !== undefined, 'Required'),
      })
    ),
    []
  ).default([]),
  sorting: fallback(
    z
      .array(
        z
          .object({
            id: z.string(),
            desc: z.boolean(),
          })
          .required()
      )
      .nonempty(),
    [...DEFAULT_SORTING]
  ).default([...DEFAULT_SORTING]),
});

export const Route = createFileRoute('/$organizationName/library')({
  component: Library,
  validateSearch: zodValidator(librarySearchSchema),
});

function Library() {
  const { organizationDisplayName } = useOrg();

  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Library`;
  }, [organizationDisplayName]);

  return (
    <>
      <DefaultLayout navbarTitle="Library">
        <div className="space-y-4 pb-12">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-medium">Library</div>
          </div>
          <LibraryTable />
        </div>
      </DefaultLayout>
    </>
  );
}

export default Library;
