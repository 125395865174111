import { useGetPrimaryAssetQuery } from '@/api/api';
import { ColumnFiltersAssetType } from '@/api/types/node';

export function useGetHasLibraryData() {
  const hasRecordsParams = {
    kind: [...ColumnFiltersAssetType],
    limit: 1,
    offset: 0,
  };

  const { data, isLoading, isFetching, refetch } = useGetPrimaryAssetQuery(hasRecordsParams);
  const recordCount = data?.results?.length ?? 0;
  const hasRecords = recordCount > 0;

  return { hasRecords, isLoading, isFetching, refetch };
}
