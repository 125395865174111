import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';

import DefaultLayout from '@/components/LayoutDefault';
import Templates from '@/components/Templates/Templates';
import { useOrg } from '@/contexts/OrgContext';

export const Route = createFileRoute('/$organizationName/templates')({
  component: TemplatesPage,
});

function TemplatesPage() {
  const { organizationDisplayName } = useOrg();
  useEffect(() => {
    document.title = `${organizationDisplayName || 'Driver'} - Templates`;
  }, [organizationDisplayName]);

  return (
    <>
      <DefaultLayout navbarTitle="Library">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-medium">Templates</div>
          </div>
          <Templates />
        </div>
      </DefaultLayout>
    </>
  );
}
