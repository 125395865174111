import { getRouteApi } from '@tanstack/react-router';
import { flexRender } from '@tanstack/react-table';
import { useState } from 'react';

import SkeletonLoader from '@/components/LibraryTable/SkeletonLoader';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

import GettingStarted from '../Guides/GettingStarted';
import { GenerationSidebar } from './GenerateDocs/GenerationSidebar';
import { Pagination } from './Pagination';
import { DataTableToolbar } from './Toolbar';
import { useLibraryTable } from './useLibraryTable';
import { useLibraryTableColumns } from './useLibraryTableColumns';

const routeApi = getRouteApi('/$organizationName/library');

export function LibraryTable() {
  const navigate = routeApi.useNavigate();
  const { table, isLoading, hasRecords, generateRow, refetchHasRecords } = useLibraryTable();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const columns = useLibraryTableColumns();

  const handleDialogOpenChanged = (isOpen: boolean) => {
    setIsDialogOpen(isOpen);

    // Refresh assets when the dialog is closed.
    if (!isOpen) {
      refetchHasRecords();
    }
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }
  if ((isDialogOpen || !hasRecords) && !isLoading) {
    return (
      <div className="flex min-h-[90vh] flex-col items-start justify-center">
        <div className="mb-10 flex flex-col space-y-2">
          <h1 className="text-2xl font-semibold">Get started with Driver</h1>
        </div>
        <GettingStarted onDialogOpenChanged={handleDialogOpenChanged} />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table} data={table.getRowModel().rows.map((row) => row.original)} />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="rounded-t-md bg-muted/50">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}
                      className="bg-transparent"
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {!isLoading &&
              table.getRowModel().rows?.length > 0 &&
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'} className="cursor-pointer">
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))}
            {table.getRowModel().rows?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Pagination table={table} />
      <GenerationSidebar
        selectedRecords={generateRow ? [generateRow] : []}
        open={!!generateRow}
        onOpenChange={(open) => {
          if (!open) {
            navigate({
              search: (prev) => {
                prev.generateRowId = '';

                return prev;
              },
            });
          }
        }}
      />
    </div>
  );
}
