import { getRouteApi } from '@tanstack/react-router';
import {
  ColumnFiltersState,
  ColumnSort,
  PaginationState,
  Updater,
  VisibilityState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';

import { driverApi, useGetPrimaryAssetQuery, useGetTagsQuery } from '@/api/api';
import {
  ColumnFiltersAssetType,
  FetchPrimaryAssetParams,
  PrimaryAsset,
  PrimaryAssetRecord,
  PrimaryAssetResponse,
  VersionStatus,
  VersionStatusType,
} from '@/api/types/node';
import { useLibraryTableColumns } from '@/components/LibraryTable/useLibraryTableColumns';
import { useAppDispatch } from '@/store';

import { useGetHasLibraryData } from './useGetHasLibraryData';
import { useLibraryStatusPoller } from './useLibraryStatusPoller';

export const DEFAULT_SORTING = [{ id: 'updated_at', desc: true }] as const;

const createTagNameToIdMap = (tags: any) => {
  const map: { [key: string]: string } = {};
  tags?.results.forEach((tag: TagResult) => {
    map[tag.name] = tag.id;
  });
  return map;
};

const routeApi = getRouteApi('/$organizationName/library');

export function useLibraryTable() {
  const navigate = routeApi.useNavigate();
  const query = routeApi.useSearch();
  const generateRowId = query.generateRowId;
  const columns = useLibraryTableColumns();
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const dispatch = useAppDispatch();

  const handlePaginationChange = (updaterOrValue: Updater<PaginationState>) => {
    const oldPaginationState = {
      pageIndex: query.pageIndex,
      pageSize: query.pageSize,
    };
    const newPagination = typeof updaterOrValue === 'function' ? updaterOrValue(oldPaginationState) : updaterOrValue;
    navigate({
      search: (prev) => ({
        ...prev,
        pageIndex: newPagination.pageIndex,
        pageSize: newPagination.pageSize,
      }),
      replace: true,
    });
  };

  const handleSortingChange = (updaterOrValue: Updater<ColumnSort[]>) => {
    let newSorting = typeof updaterOrValue === 'function' ? updaterOrValue(query.sorting) : updaterOrValue;
    if (newSorting.length === 0) {
      newSorting = [...DEFAULT_SORTING];
    }
    const newPagination = { pageIndex: 0, pageSize: query.pageSize };
    navigate({
      search: (prev) => ({
        ...prev,
        sorting: newSorting as [ColumnSort, ...ColumnSort[]],
        ...newPagination,
      }),
      replace: true,
    });
  };

  const handleColumnFiltersChange = (updaterOrValue: Updater<ColumnFiltersState>) => {
    const newFilters = typeof updaterOrValue === 'function' ? updaterOrValue(query.columnFilters) : updaterOrValue;
    const newPagination = { pageIndex: 0, pageSize: query.pageSize };
    navigate({
      search: (prev) => ({
        ...prev,
        columnFilters: newFilters,
        ...newPagination,
      }),
      replace: true,
    });
  };

  const {
    hasRecords,
    isLoading: hasRecordsLoading,
    isFetching: hasRecordsFetching,
    refetch: refetchHasRecords,
  } = useGetHasLibraryData();
  const { data: tags } = useGetTagsQuery({ type: 'tag', limit: 50, offset: 0 });
  const tagNameToIdMap = React.useMemo(() => createTagNameToIdMap(tags), [tags]);

  const params = React.useMemo(() => {
    const contentTypeNames: string[] = query.columnFilters
      .filter((filter) => filter.id === 'kind')
      .map((filter) => filter.value as string[])
      .flat();

    const filterIds: string[] = query.columnFilters
      .filter((filter) => filter.id === 'tags')
      .map((filter) => filter.value as string[])
      .flat()
      .map((tagName) => tagNameToIdMap[tagName] || tagName); // Convert tag names to IDs
    const primaryAssetType =
      contentTypeNames.length > 0
        ? contentTypeNames.map((name) => PrimaryAsset[name as keyof typeof PrimaryAsset])
        : [...ColumnFiltersAssetType];
    const searchText = query.columnFilters.find((filter) => filter.id === 'display_name')?.value as string;
    const status = query.columnFilters.find((filter) => filter.id === 'status')?.value as VersionStatusType[];

    const params: FetchPrimaryAssetParams = {
      display_name__ilike: searchText ? `%${searchText}%` : undefined,
      limit: query.pageSize,
      offset: query.pageIndex * query.pageSize,
      kind: primaryAssetType,
      sort_direction: query.sorting[0].desc ? 'DESC' : 'ASC',
      sort_by: query.sorting[0].id,
      tag_ids: filterIds,
      'versions.status': status,
    };

    return params;
  }, [query.columnFilters, query.pageIndex, query.pageSize, query.sorting, tagNameToIdMap]);

  const {
    data: response,
    isLoading: isLoadingResponse,
    isFetching: isFetchingResponse,
  } = useGetPrimaryAssetQuery(params);
  const isLoading = isLoadingResponse || hasRecordsLoading;
  const isFetching = isFetchingResponse || hasRecordsFetching;
  const data = React.useMemo(() => response?.results ?? [], [response?.results]);

  const updateCache = (row: PrimaryAssetRecord) => {
    dispatch(
      driverApi.util.updateQueryData('getPrimaryAsset', params, (draft: PrimaryAssetResponse) => {
        const assetToUpdate = draft.results.find((asset) => asset.id === row.id);
        if (assetToUpdate && assetToUpdate.most_recent_version && row.most_recent_version?.status) {
          assetToUpdate.most_recent_version = row.most_recent_version;
        }
      })
    );
  };

  // poll the status of the rows that are processing
  useLibraryStatusPoller(data, updateCache);

  const generateRow = React.useMemo(() => {
    return data.find((row) => row.id === generateRowId);
  }, [data, generateRowId]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: query.sorting,
      columnVisibility,
      rowSelection,
      columnFilters: query.columnFilters,
      pagination: { pageIndex: query.pageIndex, pageSize: query.pageSize },
    },
    getRowId: (row) => row.id,
    manualPagination: true,
    enableRowSelection: (row) => row.original.most_recent_version?.status === VersionStatus.CONNECTED,
    manualFiltering: true,
    rowCount: response?.total_count,
    onPaginationChange: handlePaginationChange,
    onRowSelectionChange: setRowSelection,
    onSortingChange: handleSortingChange,
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: handleColumnFiltersChange,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return { data, table, isLoading, isFetching, hasRecords, generateRow, refetchHasRecords };
}
